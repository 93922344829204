<template>
  <b-container>
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 text-center">
        <div class="iq-error position-relative mt-5">
          <h2 class="mb-0">{{ error_msg }}</h2>
          <span class="btn btn-primary mt-3 pointer" @click="openUrl('/home')">
            <i class="ri-home-4-line"></i>
            Back to Home
          </span>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
export default {
  name: "ErrorPage",
  data () {
    return {
      error_msg: "Page Not Found",
      error_code: "ERR_PAGE_NOT_FOUND"
    }
  },
  mounted () {
    this.error_code = this.$route.params.err ? this.$route.params.err : this.error_code
    console.log("ERRR", this.error_code)

    switch (this.error_code) {
      case "500":
        this.error_msg = "Internal Server Error"
        break

      case "ERR_PAGE_NOT_FOUND":
      case "404":
        this.error_msg = "Page Not Found"
        break

      case "ERR_ACCESS_NOT_ALLOWED":
      default:
        this.error_msg = "Unauthorised Access"
        break
    }
  },
  methods: {
    /**
     * openUrl
     */
    openUrl (url) {
      // window.open(url)
      this.$router.push(url)
    }
  }
}
</script>
